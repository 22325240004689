import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { UserService } from 'src/app/services/user/user.service';
import { C_LocalStorage } from 'src/app/utils/constant.util';
import { E_School_Type, E_roles_Name } from 'src/app/utils/enum.util';
import { I_UserConnected } from 'src/app/utils/type.util';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit {

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.autoLogout()
    this.token = this.localStorageService.value(C_LocalStorage.token);
    const jwtHelper = new JwtHelperService()
    const decodeToken = jwtHelper.decodeToken(this.token)
    this.authService.autoLogoutIfTokenExpired(decodeToken.exp)
    this.runGetUserConnected()
  }

  token: string = ""
  students: any[] = []
  items: MenuItem[] = [];
  userConnected: I_UserConnected = {} as I_UserConnected


  runGetUserConnected() {
    this.userService.getUserConnected(this.token).subscribe({
      next: (userConnected) => {
        this.userConnected = userConnected
        this.localStorageService.save(C_LocalStorage.userConected, userConnected)
        this.items = [
          {
            label: "Accueil",
            icon: 'pi pi-th-large',
            routerLink: '/dashboard/home'
          },
          {
            label: "Scolarité",
            visible: this.role(userConnected.roles, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.ROLE_SCHOOL_USER, E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.R_DGFI_ADMIN, E_roles_Name.R_DPSI_ADMIN, E_roles_Name.R_SUPER_ADMIN),
            items: [
              {
                label: "Recherche Apprenant",
                icon: 'pi pi-search',
                routerLink: '/dashboard/global_search_student',
                visible: this.role(userConnected.roles, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.ROLE_SCHOOL_USER, E_roles_Name.R_DGFI_ADMIN, E_roles_Name.R_DPSI_ADMIN, E_roles_Name.R_SUPER_ADMIN,E_roles_Name.ROLE_REGIONAL_USER),
              },
              /*{
                label: "Correction inscription",
                icon: 'pi pi-user-edit',
                routerLink: '/dashboard/correction',
                visible: this.role(userConnected.roles, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN),
              },*/
              {
                label: "Validation des inscriptions",
                icon: 'pi pi-check-square',
                routerLink: '/dashboard/school',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              },
              {
                label: "Gestion des lots",
                icon: 'pi pi-th-large',
                routerLink: '/dashboard/choice-lots',
                visible: this.role(userConnected.roles, E_roles_Name.R_DPSI_ADMIN)
              },
              {
                label: "Rejets des cartes",
                icon: 'pi pi-check-circle',
                routerLink: '/dashboard/card-validation',
                visible: this.role(userConnected.roles, E_roles_Name.R_DPSI_ADMIN)
              },
              {
                label: 'Validation Changement Etablissement',
                icon: 'pi pi-check-square',
                routerLink: '/dashboard/validation_de_changement_etablisement',
                visible: this.role(userConnected.roles, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.ROLE_SCHOOL_USER),
              },
              {
                label: 'Gestion des images',
                icon: 'pi pi-th-large',
                routerLink: '/dashboard/deco-pictures',
                visible: this.role(userConnected.roles, E_roles_Name.R_SUPER_ADMIN),
              },
              /*{
                label: 'Suivi rejet',
                icon: 'pi pi-times',
                routerLink: '/dashboard/student-rejected',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)

              },
              {
                label: 'Télécharger le fichier de moyenne',
                icon: 'pi pi-cloud-download',
                routerLink: '/dashboard/download',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              },
              {
                label: 'Importer le fichier de moyenne',
                icon: 'pi pi-cloud-upload',
                routerLink: '/dashboard/import',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              },
              {
                label: "(DFA) Saisie des décisions",
                icon: 'pi pi-check-circle',
                routerLink: '/dashboard/validate-dfa',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              },*/
              /*{
                label: "Changement de numéro",
                icon: 'pi pi-phone',
                routerLink: "/dashboard/regional.director/changement_de_numéro_de_téléphone",
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_REGIONAL_USER)
              },*/
              {
                label: "Report de scolarité",
                icon: 'pi pi-user-edit',
                routerLink: '/dashboard/postponing-schooling',
                visible: this.role(userConnected.roles, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN),
              },
              {
                label: "Mise à jour d'email",
                icon: 'pi pi-file-edit',
                routerLink: '/dashboard/change-email',
                visible: this.role(userConnected.roles,E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN)
              },
              /*{
                label: "Annulation de validation",
                icon: 'pi pi-file-edit',
                routerLink: '/dashboard/cancel-validation',
                visible: this.role(userConnected.roles,E_roles_Name.R_DGFI_ADMIN)
              }*/
            ]
          },
          {
            label: "Examen Scolaire",
            visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER, E_roles_Name.ROLE_REGIONAL_USER),
            items: [
              {
                label: "Inscriptions candidats officiels",
                icon: 'pi pi-users',
                routerLink: '/dashboard/candidat',
                visible: this.role(this.userConnected.roles!, E_roles_Name.ROLE_SCHOOL_USER),
              },
              {
                label: 'Inscription candidats libres',
                icon: 'pi pi-check-square',
                routerLink: '/dashboard/regional.director/validation',
                visible: this.role(this.userConnected.roles!, E_roles_Name.ROLE_REGIONAL_USER),
              },
              {
                label: 'Suivi Candidats',
                icon: 'pi pi-eye',
                routerLink: '/dashboard/regional.director/follow-candidate',
                visible: this.role(this.userConnected.roles!, E_roles_Name.ROLE_REGIONAL_USER),
              }
            ]
          },
          /*{
            visible: this.role(this.userConnected.roles!, E_roles_Name.R_DOBM_ADMIN),
            label: 'Validation BTS',
            icon: 'pi pi-check-square',
            routerLink: '/dashboard/regional.director/bts-validation',
          },*/
          /*{
            label: "Concours",
            // icon: 'pi pi-book',
            visible: this.role(userConnected.roles, E_roles_Name.ROLE_REGIONAL_USER) || (userConnected.competition_center ?? false),
            items: [
              {
                label: 'Validation Candidat',
                icon: 'pi pi-check-square',
                routerLink: '/dashboard/regional.director/validation_candidat_aux_concours'
              }
            ]
          },*/
          {
            label: "Candidats Banque",
            icon: 'pi pi-building',
            routerLink: '/dashboard/banque',
            visible: this.role(userConnected.roles, E_roles_Name.ROLE_BANK_USER),
          },
          {
            label: "Administration",
            visible: this.role(userConnected.roles, E_roles_Name.ROLE_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEXC_ADMIN, E_roles_Name.R_SUPER_ADMIN),
            items: [
              {
                label: "Comptes Utilisateurs",
                icon: 'pi pi-user-plus',
                routerLink: '/dashboard/admin',
              },]
          },
          {
            label: "Orientations",
            visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER, E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.R_DGFI_ADMIN, E_roles_Name.R_DOBM_ADMIN),
            items: [
              /* {
                 label: 'Choix des candidats aux passerelles',
                 icon: 'pi pi-users',
                 routerLink: '/dashboard/passerelle',
                 visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER) && userConnected.is_eligible_for_pas
               },
               {
                label: "Renonciation",
                icon: 'pi pi-ban',
                routerLink: '/dashboard/renonciation',
                visible: this.role(userConnected.roles, E_roles_Name.R_DOBM_ADMIN)
              },
              {
                label: "Statistique OAT",
                routerLink: '/dashboard/statistique',
                visible: this.role(userConnected.roles, E_roles_Name.R_DOBM_ADMIN),
              },*/
              {

                label: 'Admis OAT',
                icon: 'pi pi-database',
                routerLink: '/dashboard/download-all-admited',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              }/*,
              {

                visible: this.role(this.userConnected.roles!, E_roles_Name.R_DOBM_ADMIN),
                label: 'Validation BTS',
                // icon: 'pi pi-check-square',
                routerLink: '/dashboard/regional.director/bts-validation',
              },
              {
                visible: this.role(this.userConnected.roles!, E_roles_Name.R_DOBM_ADMIN),
                label: 'Validation des demandes de corrections',
                routerLink: '/dashboard/oat-validation-correction',
              },
              {
                label: 'Correction numéro de téléphone',
                icon: 'pi pi-file-edit',
                routerLink: '/dashboard/oat',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_REGIONAL_USER)
              },
              {
                label: 'Capacité',
                icon: 'pi pi-database',
                routerLink: '/dashboard/school-capacity',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              },
              {
                label: 'Suivi Validation Capacité',
                icon: 'pi pi-eye',
                routerLink: '/dashboard/follow-school-capacity',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              },
              {
                label: 'Validations Capacités',
                icon: 'pi pi-verified',
                routerLink: '/dashboard/capacity-validation',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.R_DGFI_ADMIN)
              },
              {
                label: 'Suivi Validations Capacités',
                icon: 'pi pi-eye',
                routerLink: '/dashboard/flollow-capacity-validation',
                visible: this.role(userConnected.roles, E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.R_DGFI_ADMIN)
              },*/
              // {
              //   label: 'Statistique Collecte de voeux',
              //   icon: 'pi pi-database',
              //   routerLink: '/dashboard/stat-oat',
              //   visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER)
              // },
             /* {
                label: 'Corrections de nationalité',
                // icon: 'pi pi-user-edit',
                routerLink: '/dashboard/nationality-correction',
                visible: this.role(userConnected.roles, E_roles_Name.R_DOBM_ADMIN, E_roles_Name.ROLE_REGIONAL_USER)
              },
              {
                label: "Établissements à l'OAT 2024",
                // icon: 'pi pi-users',
                routerLink: '/dashboard/oat-school-retained',
                visible: this.role(userConnected.roles, E_roles_Name.R_DOBM_ADMIN, E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.R_DEEP_ADMIN, E_roles_Name.R_DEP_ADMIN, E_roles_Name.R_DGFI_ADMIN)
              },*/
            ]
          },
          // {
          //   label: "Stage CAN",
          //   visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER) && userConnected.is_professional,
          //   items: [
          //     {
          //       label: 'Inscription',
          //       icon: 'pi pi-users',
          //       routerLink: '/dashboard/internship-inscription',
          //       visible: this.role(userConnected.roles, E_roles_Name.ROLE_SCHOOL_USER) && userConnected.is_professional,
          //     },
          //   ]
          // },
          {
            label: "Statistiques",
            visible: this.role(userConnected.roles, E_roles_Name.R_DEXC_ADMIN, E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.ROLE_SCHOOL_USER, E_roles_Name.R_DGFI_ADMIN),
            items: [
              {
                label: "suivis examen",
                icon: 'pi pi-users',
                routerLink: '/dashboard/dexc/analytics',
                visible: this.role(userConnected.roles, E_roles_Name.R_DEXC_ADMIN, E_roles_Name.ROLE_REGIONAL_USER, E_roles_Name.ROLE_SCHOOL_USER, E_roles_Name.R_DGFI_ADMIN)
              },
              /*{
                label: "suivis concours",
                icon: 'pi pi-users',
                routerLink: 'dexc/statistiques_candidats_concours',
                visible: this.role(userConnected.roles, E_roles_Name.R_DEXC_ADMIN, E_roles_Name.R_DGFI_ADMIN),
              },*/
            ]
          },
          /*{
            label: "Validation Banque",
            icon: 'pi pi-users',
            routerLink: '/dashboard/dexc/validation',
            visible: this.role(userConnected.roles, E_roles_Name.R_DEXC_ADMIN),
          },*/
        ];
      }, error: (err) => {
        console.log("err", err)
      },
    })
  }

  runLogout() {
    this.userService.logout(this.token).subscribe({
      next: (isLogout) => {
        if (isLogout) {
          this.localStorageService.remove(C_LocalStorage.token)
          this.localStorageService.remove(C_LocalStorage.userConected)
          this.router.navigate(['login'])
        }
      }, error: (err) => {
        console.log("err", err)
      },
    })
  }

  role(role: string, ...roles: E_roles_Name[]) {
    return roles.includes(role as E_roles_Name)
  }


}
